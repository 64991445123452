import React from 'react'
import Container from 'react-bootstrap/Container'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import TabWrapper from '../../TabWrapper'
import ListManagers from './ListManagers'
import ManagementTabRow from './ManagementTabRow'
import { blockManagementTabs } from './ManagementTabs.module.scss'

const ManagementTabs = ({ ...props }) => {
	const { teamLeaders, nonLegalManagement } = props
	const activeTab = teamLeaders.team ? 'team-leaders' : 'services-leaders'
	return (
		<>
			<div className={blockManagementTabs}>
				<Container fluid="lg">
					<TabWrapper>
						<Tabs defaultActiveKey={activeTab} id="uncontrolled-tab-example">
							{teamLeaders.team && (
								<Tab eventKey="team-leaders" title="Team Leaders">
									{teamLeaders.team.map(team => {
										return (
											<ManagementTabRow
												key={team.teamLabel}
												teamLabel={team.teamLabel}
												leaders={[team.teamLeader]}
											/>
										)
									})}
								</Tab>
							)}

							<Tab eventKey="sectors-leaders" title="Sector Group Leaders">
								<ListManagers type="sector" />
							</Tab>
							<Tab eventKey="services-leaders" title="Service Group Leaders">
								<ListManagers type="service" />
							</Tab>

							{nonLegalManagement.nonLegalMember && (
								<Tab
									eventKey="non-legal-management"
									title="Non-Legal Management"
								>
									{nonLegalManagement.nonLegalMember.map(leader => (
										<ManagementTabRow key={leader.id} leaders={[leader]} />
									))}
								</Tab>
							)}
						</Tabs>
					</TabWrapper>
				</Container>
			</div>
		</>
	)
}

export default ManagementTabs
